const initialState = {
    hideLogoMenu: false,
}

const TOGGLE_HIDELOGOMENU = 'TOGGLE_HIDELOGOMENU'


export const toggleHideLogoMenu = hideLogoMenu => ({
    type: TOGGLE_HIDELOGOMENU, hideLogoMenu
})


const App =  (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_HIDELOGOMENU:
            return { ...state, hideLogoMenu: action.hideLogoMenu }
        default:
            return state
    }
}
export default App